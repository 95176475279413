<template>
  <div v-if="this.quotations">
    <div class="container" v-show="filter.flag">
      <SelectedFilters v-if="selectFilters.length !== 0" :data="selectFilters" @sendChange="filterChange = $event"/>
      <Search :isActiveFilter="btnFilter" @inputchange="search" @filter="filter.flag = false" v-if="quotations !== null" />
      <p class="list-not" v-if="listNot">{{$root.langText.notFound}}</p>
      <ul class="block-list" >
        <router-link 
          tag="li"
          :key="index"
          v-for="(quotations, index) in quotationsNew"
          :id="quotations.id"
          :to = "{name: 'QuotationsCreateId', params: {id: quotations.id}}" 
          
        >
          <a style="display: block" v-if="$root.app !== 'SoftProCrmAppRioTransTest' && $root.app !== 'SoftProCrmAppRioTrans'">
            <i :style="{background: quotations.status_bg_color}"></i>
            <strong :style="{color: quotations.status_color_text}">{{quotations.status_name}}</strong>
            <h4>#{{quotations.number}} 
              <p>{{$root.langText.from}} {{quotations.created_at | date}}</p></h4>
              <p>{{quotations.client_name ? quotations.client_name : '-'}}</p>
              <p v-if="$root.app == 'SoftProCrmAppOneWhale'">{{quotations.creator_name}}</p>
            <div class="icon icon-arrow-right"></div>
          </a>


          <a style="display: block" v-else>
            <s v-if="quotations.item_viewed !== null" :style="{background: quotations.statusColorBG}"></s>
            <i :style="{background: quotations.status_bg_color}"></i>
            <div style="position: relative;z-index:2">
              <h4 style="display:flex;justify-content: space-between;">
                <strong :style="{color: quotations.item_viewed !== null ? '#004D9D' : quotations.status_color_text, 'text-transform': 'none'}">{{quotations.status_name}}</strong>
                <p style="font-weight:600">№{{quotations.number}}</p>
              </h4>
              <p style="font-weight:500;margin-bottom: 15px">{{quotations.client_name ? quotations.client_name : '-'}}</p>
              
              <div 
                style="font-weight:500;line-height:0.9"
                v-for="(item, index) in quotations.quotes_directions"
                :key="index"
              >
                <p v-if="item.poo_name && item.fd_name" style="display:flex">
                  {{item.poo_name}} - {{item.fd_name}}
                  <span 
                    style="margin: 1px 0 0 5px; color:#004d9d" 
                    v-if="item.shipment_date_from && item.shipment_date_from !== '0000-00-00 00:00:00' && item.shipment_date_from !== '0000-00-00'"
                  >
                    ({{item.shipment_date_from | date}})
                  </span>
                </p>
                
              </div>
            </div>
            <div class="icon icon-arrow-right"></div>
          </a>
        </router-link >
      </ul>
      <router-link v-if="$root.add.can_add_quote" to="/quotes/new" class="add-plus"></router-link>
       <Pagination v-if="quantityList && quantityList > params.limit" @pagination="pagination" :dataList="{quantityList, params}"/>
    </div>
    <Filters @filter="filters" :filterChange="filterChange" :filter="filter"/>
  </div>
</template>


<style lang="scss">
  .block-list li h4{
    text-transform: none;
  }
</style>



<script>
import axios from "axios"
import Search from "@/components/app/Search"
import Filters from "@/components/app/Filters"
import Pagination from "@/components/app/Pagination"
import SelectedFilters from "@/components/app/SelectedFilters"

export default {
  data: () => ({
    //title
    globalTitle: [
      {title: 'Мої котирування', info: ''}
    ],

    filter: {
      flag: true,
      mod: 'quotations'
    },
    btnFilter: false,
    quotations: null,
    quotationsNew: [],
    setinerval: '',


    //
    searchData: '',
    filtersData: '',
    filterChange: '',
    selectFilters: [],

    //pagination
    params: {
      start: 0, 
      limit: 20,
    },
    quantityList: '',
    arrFilters: '',
    listNot: false
  }),




  async created() {
    //reload filters
    function getSearchParams(k){
      var p={};
      decodeURIComponent(document.location.search).replace(/[?&]+([^=&]+)=([^&]*)/gi,function(s,k,v){p[k]=v})
      return k?p[k]:p;
    }
    var efe = getSearchParams()
    if(efe['f[start]']) {
      this.params.start = efe['f[start]']
    }
    if(efe['f[search]']) {
      this.searchData = 'f%5Bsearch%5D=' + efe['f[search]']
    }
    this.filtersData = window.location.search
    this.arrFilters = window.location.search.slice(1)


    //title
    if(this.$root.app == 'SoftProCrmAppRioTransTest' || this.$root.app == 'SoftProCrmAppRioTrans'){
      this.globalTitle[0].title = this.$root.langText.menu.quotes;
    } else{
      this.globalTitle[0].title = this.$root.langText.menu.quotesOther;
    }
    this.$root.title = this.globalTitle;

    const res = await this.$store.dispatch('postFiltersQuotations', {params: this.params, data: this.arrFilters})
    this.quotations = res.data;
    if(this.quotations){
      this.quotationsNew = res.data.list;
      this.quantityList = this.quotations.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }




      //notification
      localStorage.setItem('notificationQuotes', false)
      var date = new Date().getTime() / 1000
      let app = this
      let arr = []
      this.setinerval = window.setInterval(async () => {
        
        app.quotationsNew.forEach(function(item, index){
          arr.push(item.id)
        })
        var data = {
          action: 'leads_list',
          ids: arr,
          date_from: date
        }
        var natification = await app.$store.dispatch('getNatification', data)
        if(natification.data.has_updates){
          const res = await app.$store.dispatch('postFiltersQuotations', {params: this.params, data: this.arrFilters});
          app.quotations = res.data;
          app.quotationsNew = res.data.list;
          app.quantityList = app.quotations.total_num
        }
      }, 3000)
    }
  },
  methods: {

    //function filtration
    async reloadFilters(mod){
      if(mod !== 'pagination'){
        this.params.start = 0
      }
      this.arrFilters = this.searchData + "&" + this.filtersData + '&f%5Bstart%5D=' + this.params.start
      window.history.pushState('', '', window.location.origin + window.location.pathname + '?' + this.arrFilters)
      var arrFilters = await this.$store.dispatch('postFiltersQuotations', {params: this.params, data: this.arrFilters})
      this.quotationsNew = arrFilters.data.list
      this.quantityList = arrFilters.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },


    //filters
    async filters(data){
      this.filter.flag = true
      if(data == undefined){
        this.filtersData = '';
        this.btnFilter = false;
        this.selectFilters = [];
        this.reloadFilters('filters');
      } else{
        if(data.filter == ''){
          this.btnFilter = false
        } else{
          this.btnFilter = true
        }
        this.selectFilters = data.dataIsFilter
        this.filtersData = data.filter
        if(data.load !== true){
          this.reloadFilters('filters');
        }
      }
    },

    //search
    async search(data) {
      if(data !== undefined && data.load !== true){
        this.searchData = data.search
      }
      if(data.load !== true){
        this.reloadFilters('search');
      }
    },

    //paginations
    async pagination(data){
      this.params.start = data.start
      if(data.load !== true){
        this.reloadFilters('pagination')
      }
      window.scrollTo(0,0);
    }


    
  },

  beforeDestroy() {
    clearInterval(this.setinerval)
  },

  components: {
    Search, Filters, Pagination, SelectedFilters
  },
  metaInfo(){
    var title = this.$root.langText.directory.quotesOther;
    if(this.$root.app == 'SoftProCrmAppRioTransTest' || this.$root.app == 'SoftProCrmAppRioTrans'){
      title = this.$root.langText.directory.quotes
    }
    return {
      title: this.$title(title)
    }
  },
}

</script>